
import { Component } from 'vue-property-decorator';
import DetailsComponent from '@/shared/components/layout/details/details-component';
import { parseDate } from '@/shared/helpers';
import TopToolbar from '@/shared/components/layout/details/top-toolbar.vue';

import { mdiPencil } from '@mdi/js';
import SalesForm from '@/modules/sales/components/form.vue';

import StatusesPicker from '@/modules/sales/components/pickers/status-picker.vue';

import { salesModule, Sale } from '@/modules/sales/shared';
import { FormRules } from '@/shared/validation/form-rules';
import SaleNotes from '@/modules/sales/components/messages/notes.vue';
import UserPicker from '@/modules/sales/components/pickers/user-picker.vue';
import {investmentSubjectsModule} from '@/modules/investments/shared/state/submodules';
import SubjectSimpleDetails from '@/modules/investments/components/subjects/simple-details.vue';
import {ModuleShowPayload} from '@/shared/state/template/module-payloads';
import ContactCard from './details/contact.vue';
import ChecklistCard from '@/modules/sales/components/details/checklist.vue';
import { leadSources } from '@/shared/config/sales';
import { contrast, hexToRgb } from '@/shared/helpers/colors';
import ObservableWatch from '@/shared/components/elements/observable-watch.vue';
import LinkedEvents from '@/modules/calendar/components/linked-events.vue';
import { httpClient } from '@/shared/services';
import ContractorForm from '@/modules/contractors/components/form.vue';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';
import { ContractorDataRequest } from '@/modules/contractors/shared/requests/contractor-data-request';

@Component({
    props: {
        id: String,
    },
    components: {
        LinkedEvents,
        ChecklistCard,
        ContactCard,
        SubjectSimpleDetails,
        TopToolbar,
        StatusesPicker,
        SaleNotes,
        UserPicker,
        SalesForm,
        ObservableWatch,
        ContractorForm,
        TooltipBtn,
    },
})
export default class SalesDetails extends DetailsComponent<Sale> {
    get leadSource() {
        const source = leadSources.find((e) => e.id === this.item.source);
        return source
            ? source
            : {
                  id: 'unknown',
                  name: 'Nieznane',
                  color: '#ccc',
                  icon: 'mdi-help',
              };
    }

    get subjectLoading() {
        return this.$store.state.investmentsState.subjectsState.loadingItem;
    }

    get subject() {
        return this.$store.state.investmentsState.subjectsState.current;
    }
    public store: any = this.$store.state.salesState;
    public actionsTypes = salesModule.actionsTypes;
    public subjectActionsTypes = investmentSubjectsModule.actionsTypes;
    public showAction: string = this.actionsTypes.SHOW_ITEM;
    public showSubject: string = this.subjectActionsTypes.SHOW_ITEM;
    public parseDate = parseDate;
    public tab: any = 0;
    public formRules = new FormRules();
    public formDialog = false;
    public editedItem = {};
    public icons = {
        mdiPencil,
    };

    public newContractor = {} as ContractorDataRequest;
    public showContractorForm = false;
    public contractorCreatedDialog = false;

    private whiteColor = hexToRgb('#ffffff');
    private blackColor = hexToRgb('#000000');

    public created() {
        this.fetchDetails();
    }

    public isPermitted(action: string) {
        return this.permissionCheck(`sales.${action}`);
    }

    public fetchCallback({ data }: ModuleShowPayload<Sale>) {
        if (data && data.investment_subject_id) {
            this.$store.dispatch(this.showSubject, data.investment_subject_id);
        }
    }

    public isDark(color: string) {
        return contrast(hexToRgb(color), this.whiteColor) > 2;
    }

    public isLight(color: string) {
        return contrast(hexToRgb(color), this.blackColor) > 2;
    }

    public editItemToolbar() {
        this.formDialog = true;
        this.editedItem = { ...this.item };
    }

    public closeForm() {
        this.formDialog = false;
    }

    public itemSaved() {
        this.formDialog = false;
        this.fetchDetails();
    }

    public deleteItem() {
        httpClient.delete(`api/v1/sales/${this.id}`).then((res) => {
            if (res.status === 204) {
                this.$store.commit('SHOW_SNACKBAR', {
                    type: 'success',
                    text: 'Pomyślnie usunięto element',
                });
            } else {
                this.$store.commit('SHOW_SNACKBAR', {
                    type: 'success',
                    text: 'Coś poszło nie tak',
                });
            }
            this.$router.replace({ name: 'sales-list' });
        });
    }

    public convertToContractor() {
        this.newContractor.name = this.item.name;
        this.newContractor.contractor_type_id = 1;
        this.newContractor.sale_id = this.item.id;
        this.newContractor.user_id = this.item.assigned_user_id;
        this.newContractor.related_investment_subject_id = this.item.investment_subject_id;
        if (this.item.phone) {
            this.newContractor.phones = [this.item.phone];
        }
        if (this.item.email) {
            this.newContractor.emails = [this.item.email];
        }
        this.showContractorForm = true;
    }

    public onContractorSaved() {
        this.fetchDetails();
        this.$nextTick(() => {
            this.showContractorForm = false;
            this.contractorCreatedDialog = true;
        });
    }
}
