
import ListComponent from '@/shared/components/layout/list/list-component';
import {ModuleProps} from '@/shared/state/template/module-props';
import {
    SaleStatus,
    SaleStatusDataRequest,
    statusesModule,
    SaleDataRequest,
    salesModule,
} from '@/modules/sales/shared';
import Component from 'vue-class-component';
import draggable from 'vuedraggable';
import kanbanTable from './table.vue';
import salesForm from '../form.vue';
import statusesForm from '../settings/status/form.vue';
import {DataRequest} from '@/shared/types';
import {Prop, Watch} from 'vue-property-decorator';
import {investmentsModule} from '@/modules/investments/shared/state/module';
import {Investment} from '@/modules/investments/shared/models/investment';

@Component({
    components: {
        draggable,
        kanbanTable,
        salesForm,
        statusesForm,
    },
    props: {
        filters: {
            type: String,
            default: '{}',
        },
        searchSales: String,
        statuses: Array,
    },
})
export default class Kanban extends ListComponent<SaleStatus, SaleStatusDataRequest> {
    @Prop(Boolean) public investmentGrouping!: boolean;

    public headers: object[] = [];
    public actionsTypes = statusesModule.actionsTypes;
    public mutationTypes = statusesModule.mutationsTypes;
    public saleMutationTypes = salesModule.mutationsTypes;
    public fetchAction: string = this.actionsTypes.FETCH_DATA;
    public removeAction: string = this.actionsTypes.REMOVE_ITEM;
    public store: any = this.$store.state.salesState.statusesState;
    public props: ModuleProps = statusesModule.moduleProps;
    public statuses!: SaleStatus[];

    public salesState = this.$store.state.salesState;
    public headerMouseOver = false;
    public showAddStatus = false;

    public salesFormDialog = false;

    public statusesFormDialog = false;

    public searchSalesCopy = '';
    public searchTimer: number = 0;

    get saleFormDialog() {
        return this.salesState.formDialog;
    }

    set saleFormDialog(value) {
        this.$store.commit(this.saleMutationTypes.UPDATE_FORM_DIALOG, value);
    }

    get editedSale() {
        return this.salesState.editedItem;
    }

    set editedSale(value) {
        this.$store.commit(this.saleMutationTypes.UPDATE_EDITED, value);
    }

    get currentUser() {
        return this.$store.state.authState.user;
    }

    get investments() {
        return this.$store.state.investmentsState.dataList;
    }

    public onTableLoading(isTableLoaded: boolean) {
        if (isTableLoaded) {
            this.showAddStatus = true;
        }
    }

    public createSale(id: number) {
        this.statusesFormDialog = false;

        this.editedSale = Object.assign({
            sale_status_id: id,
            status: this.statuses.find((item) => item.id === id),
        }, {} as SaleDataRequest);
        this.saleFormDialog = true;
    }

    public createStatus() {
        this.statusesFormDialog = true;
        this.createItem();
        this.formDialog = true;
    }

    @Watch('searchSales')
    public onSearchChange(val: string) {
        clearTimeout(this.searchTimer);
        this.searchTimer = window.setTimeout(() => {
            this.searchSalesCopy = val;
        }, 900);
    }

    public onItemSaved({data}: any) {
        this.formDialog = false;
        this.salesState.statusWithNewSale = data.sale_status_id;
    }

    public onItemNext(data: any) {
        this.formDialog = true;
        this.salesState.statusWithNewSale = data.sale_status_id;
    }
}
