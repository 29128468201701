
import ListComponent from '@/shared/components/layout/list/list-component';
import {ModuleProps} from '@/shared/state/template/module-props';
import {SaleStatus, SaleStatusDataRequest, statusesModule, SaleDataRequest} from '@/modules/sales/shared';
import Component from 'vue-class-component';
import salesForm from '../form.vue';
import statusesForm from '../settings/status/form.vue';
import {DataRequest} from '@/shared/types';
import {Prop, Watch} from 'vue-property-decorator';
import SubList from './sub-list.vue';

@Component({
    components: {
        salesForm,
        statusesForm,
        SubList,
    },
    props: {
        filters: {
            type: String,
            default: '{}',
        },
        searchSales: String,
        statuses: Array,
    },
})
export default class Kanban extends ListComponent<SaleStatus, any> {
    @Prop(Boolean) public investmentGrouping!: boolean;

    public headers: object[] = [];
    public actionsTypes = statusesModule.actionsTypes;
    public mutationTypes = statusesModule.mutationsTypes;
    public fetchAction: string = this.actionsTypes.FETCH_DATA;
    public removeAction: string = this.actionsTypes.REMOVE_ITEM;
    public store: any = this.$store.state.salesState.statusesState;
    public props: ModuleProps = statusesModule.moduleProps;

    public salesState = this.$store.state.salesState;
    public headerMouseOver = false;
    public showAddStatus = false;

    public salesFormDialog = false;

    public statusesFormDialog = false;

    public searchSalesCopy = '';
    public searchTimer: number = 0;

    public mounted() {
        return;
    }

    public onTableLoading(isTableLoaded: boolean) {
        if (isTableLoaded) {
            this.showAddStatus = true;
        }
    }

    get investments() {
        return this.$store.state.investmentsState.dataList;
    }

    public createSale(id: number) {
        this.salesFormDialog = true;
        this.statusesFormDialog = false;

        this.setDefaultData({sale_status_id: id} || ({} as SaleDataRequest));
        this.formDialog = true;
    }

    public createStatus() {
        this.salesFormDialog = false;
        this.statusesFormDialog = true;
        this.createItem();
        this.formDialog = true;
    }

    @Watch('searchSales')
    public onSearchChange(val: string) {
        clearTimeout(this.searchTimer);
        this.searchTimer = window.setTimeout(() => {
            this.searchSalesCopy = val;
        }, 900);
    }
}
