
import { Component, Watch, Vue, Prop, Inject, Ref } from 'vue-property-decorator';
import sale from './sale.vue';
import draggable from 'vuedraggable';
import { salesModule } from '@/modules/sales/shared/state/module';
import { httpClient, logger } from '@/shared/services';
import { Sale } from '../../shared';
import { SalesState } from '../../shared/state/state';
import { objectToQueryString } from '@/shared/helpers';
import InfiniteScroll from '@/shared/components/elements/infinite-scroll.vue';
import { MetaItem } from '@/shared/types';
import SaleLoader from '@/shared/components/loaders/sale-loader.vue';

@Component({
    components: {
        draggable,
        sale,
        InfiniteScroll,
        SaleLoader,
    },
})
export default class Table extends Vue {
    @Inject() public readonly permissionCheck!: (arg1: string) => boolean;
    @Inject() public readonly salesEventBus!: Vue;

    @Ref() public scrollable!: HTMLElement;

    @Prop(String) public title!: string;
    @Prop(Number) public statusId!: number;
    @Prop(String) public filters!: string;
    @Prop(String) public search!: string;
    @Prop([Number, String]) public investmentId!: number | string;

    public actionsTypes = salesModule.actionsTypes;
    public mutationsTypes = salesModule.mutationsTypes;
    public items: any = [];
    public isLoading = true;
    public headerMouseOver = false;
    public store: SalesState = this.$store.state.salesState;
    public draggedElement: HTMLDivElement | null = null;
    public selectedItem: Sale | null = null;
    public props = salesModule.moduleProps;
    public stopInfiniteScroll = false;
    public salesCount: number = 0;
    public meta = {} as MetaItem;

    public dragOptions = {
        animation: 200,
        disabled: false,
        ghostClass: 'ghost',
    };

    get isAnySaleDragged() {
        return this.store.isAnySaleDragged;
    }

    set isAnySaleDragged(value) {
        this.$store.commit(this.mutationsTypes.SET_SALE_DRAGGED, value);
    }

    get user() {
        return this.$store.state.authState.user;
    }

    get fetchParams() {
        return {
            filters: {
                ...JSON.parse(this.$props.filters),
                investment_id: this.investmentId || null,
                search: this.$props.search,
            },
        };
    }

    get tableToRefresh() {
        return this.$store.state.salesState.statusWithNewSale === this.$props.statusId;
    }

    public created() {
        this.salesEventBus.$on('saleDropped', (statusId: number) => {
            if (this.statusId !== statusId) {
                return;
            }
            this.salesCount++;
        });
    }

    public mounted() {
        this.fetchData();
    }

    public createSale() {
        this.$emit('createSale', this.$props.statusId);
    }

    public appendExtra({ data }: any) {
        this.items.push(...data.data);
    }

    public onDrag(e: any) {
        const vMain = document.querySelector('.v-main');
        vMain?.appendChild(e.from.querySelector('.sortable-fallback'));
        this.selectedItem = this.items[e.oldIndex];
        this.store.isAnySaleDragged = true;
        this.salesCount--;
    }

    public onDrop(e: any) {
        const oldParent = e.from;
        const newParent = e.to;

        this.store.isAnySaleDragged = false;
        if (oldParent === newParent || !this.selectedItem) {
            this.salesCount++;
            return;
        }

        let newStatusId;
        newParent.className.split(' ').forEach((element: string) => {
            if (element.includes('status-')) {
                newStatusId = parseInt(element.split('-')[1], 10);
            }
        });

        if (newStatusId === undefined) {
            return;
        }
        this.selectedItem.sale_status_id = newStatusId;

        this.$store.dispatch(this.actionsTypes.UPDATE_ITEM, {
            id: this.selectedItem.id,
            sale_status_id: newStatusId,
        });
        this.selectedItem = null;

        this.salesEventBus.$emit('saleDropped', newStatusId);
    }

    public onRemove(id: number) {
        this.items = this.items.filter((el: Sale) => {
            return el.id !== id;
        });
    }

    public fetchData() {
        if (this.tableToRefresh) {
            this.$store.state.salesState.statusWithNewSale = null;
        }

        this.isLoading = true;
        this.stopInfiniteScroll = false;

        let url = `/api/v1/${this.props.mockURLName || this.props.name}/statuses/${this.$props.statusId}/${
            this.props.mockURLName || this.props.name
        }?`;

        url += objectToQueryString(this.fetchParams);

        httpClient
            .get(url)
            .then((data) => {
                this.stopInfiniteScroll = false;
                this.items = data.data.data;
                this.isLoading = false;
                this.salesCount = data.data.meta.total;
                this.meta = data.data.meta;
                if (data.data.meta.total <= 10) {
                    this.stopInfiniteScroll = true;
                }
            })
            .catch((err) => {
                logger.error(err);
                this.isLoading = false;
            });
    }

    @Watch('tableToRefresh')
    public onTableToRefreshChange(val: any) {
        if (!val) {
            return;
        }
        this.fetchData();
    }

    @Watch('filters')
    public onFiltersChanged(val: any) {
        this.items = [];
        this.fetchData();
    }

    @Watch('search')
    public onSearchChanged(val: any) {
        this.items = [];
        this.fetchData();
    }

    @Watch('isLoading')
    public onIsLoadingChanged(val: boolean) {
        this.$emit('loading', !val);
    }
}
