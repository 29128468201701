
import Vue from 'vue';
import Component from 'vue-class-component';
import { contrast, hexToRgb } from '@/shared/helpers/colors';
import router from '@/router';
import { salesModule } from '@/modules/sales/shared';
import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';
import UserPicker from '@/modules/sales/components/pickers/user-picker.vue';
import NameReduce from '@/shared/components/elements/name-reduce.vue';
import { SalesState } from '../../shared/state/state';
import { Inject, Prop } from 'vue-property-decorator';
import { leadSources } from '@/shared/config/sales';
import { InvestmentSubject } from '@/modules/investments/shared/models/investment-subject';
import { Investment } from '@/modules/investments/shared/models/investment';
import { User } from '@/modules/admin/shared/models/user';
import { parseDate } from '@/shared/helpers';

@Component({
    components: {
        ActionConfirmDialog,
        UserPicker,
        NameReduce,
    },
    props: {
        title: String,
        name: String,
        noClickEvents: Boolean,
        noExpanding: Boolean,
        draggable: Boolean,
    },
})
export default class SaleCard extends Vue {
    @Inject() public readonly permissionCheck!: (...permissions: string[]) => boolean;

    @Prop(Number) public id!: number;
    @Prop(String) public source!: string;
    @Prop(String) public question!: string;
    @Prop(String) public phone!: string;
    @Prop(String) public email!: string;
    @Prop(Object) public investment!: Investment;
    // tslint:disable-next-line:variable-name
    @Prop(Object) public investment_subject!: InvestmentSubject;
    // tslint:disable-next-line:variable-name
    @Prop(String) public updated_at!: string;
    // tslint:disable-next-line:variable-name
    @Prop(String) public created_at!: string;
    // tslint:disable-next-line:variable-name
    @Prop(Object) public assigned_user!: User;
    // tslint:disable-next-line:variable-name
    @Prop(Number) public assigned_user_id!: number;
    // tslint:disable-next-line:variable-name
    @Prop(String) public due_date!: string;

    public $refs!: {
        saleRef: HTMLDivElement;
    };
    public store: SalesState = this.$store.state.salesState;
    public actionsTypes = salesModule.actionsTypes;
    public mouseOver = false;
    public assignedUser = {};
    public assignedUserLoading = false;
    public parseDate = parseDate;
    public items = [
        {
            icon: 'mdi-card-text',
            title: 'Zobacz więcej',
            action: () => {
                router.push(`/sales/${this.$props.id ? this.$props.id : ''}`);
            },
        },
        {
            icon: 'mdi-delete',
            iconColor: 'error',
            title: 'Usuń',
            action: this.showRemoveDialog,
        },
    ];

    public removeDialog = false;

    private whiteColor = hexToRgb('#ffffff');
    private blackColor = hexToRgb('#000000');

    get isAnySaleDragged() {
        return this.store.isAnySaleDragged;
    }

    set isAnySaleDragged(value) {
        this.store.isAnySaleDragged = value;
    }

    get currentUser() {
        return this.$store.state.authState.user;
    }

    public generateName(name: string) {
        const firstName = name.split(' ')[0];
        const lastName = name.split(' ')[1];

        return firstName + ' ' + lastName[0] + '.';
    }

    get leadSource() {
        const source = leadSources.find((e) => e.id === this.source);
        return source
            ? source
            : {
                  id: 'unknown',
                  name: 'Nieznane',
                  color: '#ccc',
                  icon: 'mdi-help',
              };
    }

    public mounted() {
        this.assignedUser = this.$props.assigned_user;
    }

    public showRemoveDialog() {
        this.removeDialog = true;
    }

    public closeRemoveDialog() {
        this.removeDialog = false;
    }

    public deleteSale() {
        this.$store.dispatch(this.actionsTypes.REMOVE_ITEM, this.$props.id);
        this.$emit('remove');
        this.removeDialog = false;
    }

    public isDark(color: string) {
        return contrast(hexToRgb(color), this.whiteColor) > 2;
    }

    public isLight(color: string) {
        return contrast(hexToRgb(color), this.blackColor) > 2;
    }

    public assignUser(e: any) {
        this.assignedUserLoading = true;
        e.then((res: any) => {
            this.$store.commit('SHOW_SNACKBAR', {
                type: 'success',
                text: `Osoba została przypisana do zadania`,
            });
            this.assignedUser = res.data.data.assigned_user;
            this.$emit('update:assigned_user', this.assignedUser);
            this.$emit('update:assigned_user_id', res.data.data.assigned_user);
        })
            .catch((err: any) => {
                this.$store.commit('SHOW_SNACKBAR', { type: 'error', text: 'Coś poszło nie tak' });
            })
            .finally(() => {
                this.assignedUserLoading = false;
            });
    }

    public removeAssignedUser() {
        this.assignedUserLoading = true;
        this.$store
            .dispatch(this.actionsTypes.UPDATE_ITEM, {
                id: this.$props.id,
                assigned_user_id: null,
            })
            .then((val) => {
                this.assignedUser = val.data.assigned_user;
                this.assignedUserLoading = false;
            });
    }

    public onSaleClick(e: any) {
        router.push({ name: 'sale-details', params: { id: String(this.id) } });
    }
}
