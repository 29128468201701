
import Vue from 'vue';
import { Component, Inject, Prop } from 'vue-property-decorator';
import { SaleTask } from '@/modules/sales/shared/models/task';
import { tasksModule } from '@/modules/sales/shared';
import { logger } from '@/shared/services';

@Component
export default class ChecklistCard extends Vue {
    @Inject() public readonly permissionCheck!: (...permissions: string[]) => boolean;

    @Prop(Array) public tasks!: SaleTask[];
    @Prop(String) public color!: string;
    public actionsTypes = tasksModule.actionsTypes;

    public updateTask(task: SaleTask) {
        this.$store
            .dispatch(this.actionsTypes.UPDATE_ITEM, task)
            .then((response) => {
                if (response.data && this.tasks.every(({ checked }) => checked)) {
                    this.$emit('completed');
                }
            })
            .catch((e) => logger.error(e));
    }

    public isPermitted(action: string) {
        return this.permissionCheck(`sales.tasks.${action}`);
    }
}
