
import { objectToQueryString } from '@/shared/helpers';
import draggable from 'vuedraggable';

import { httpClient, logger } from '@/shared/services';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject, Prop, Ref, Watch } from 'vue-property-decorator';
import { Sale, salesModule, statusesModule } from '@/modules/sales/shared';
import item from './item.vue';
import { SalesState } from '../../shared/state/state';
import { MetaItem } from '@/shared/types';
import InfiniteScroll from '@/shared/components/elements/infinite-scroll.vue';

@Component({
    components: {
        item,
        draggable,
        InfiniteScroll,
    },
})
export default class SubList extends Vue {
    @Inject() public readonly permissionCheck!: (...permissions: string[]) => boolean;

    @Ref() public scrollable!: HTMLElement;

    @Prop(String) public title!: string;
    @Prop(Number) public statusId!: number;
    @Prop(String) public filters!: string;
    @Prop(String) public search!: string;
    @Prop(Number) public investmentId!: number;

    public actionsTypes = salesModule.actionsTypes;
    public selectedItem: Sale | null = null;
    public items: any = [];
    public store: SalesState = this.$store.state.salesState;
    public dragOptions = {
        animation: 200,
        disabled: false,
        ghostClass: 'ghost',
    };
    public props = salesModule.moduleProps;
    public isLoading = false;
    public stopInfiniteScroll = false;
    public salesCount: number = 0;
    public meta = {} as MetaItem;

    get fetchParams() {
        return {
            filters: {
                ...JSON.parse(this.filters),
                investment_id: this.investmentId || null,
                search: this.search,
            },
            is_extra_fetch: true,
        };
    }

    get isAnySaleDragged() {
        return this.store.isAnySaleDragged;
    }

    set isAnySaleDragged(value) {
        this.store.isAnySaleDragged = value;
    }
    get tableToRefresh() {
        return this.$store.state.salesState.statusWithNewSale;
    }

    public mounted() {
        this.fetchData();
    }

    public appendExtra({ data }: any) {
        this.items.push(...data.data);
    }

    public onRemove(id: number) {
        this.items = this.items.filter((el: Sale) => {
            return el.id !== id;
        });
    }

    public onDrag(e: any) {
        const vMain = document.querySelector('.v-main');
        vMain?.appendChild(e.from.querySelector('.sortable-fallback'));
        this.selectedItem = this.items[e.oldIndex];
        this.isAnySaleDragged = true;
    }

    public onDrop(e: any) {
        const oldParent = e.from;
        const newParent = e.to;

        this.store.isAnySaleDragged = false;
        if (oldParent === newParent || !this.selectedItem) {
            return;
        }

        let newStatusId;
        newParent.className.split(' ').forEach((element: string) => {
            if (element.includes('status-')) {
                newStatusId = parseInt(element.split('-')[1], 10);
            }
        });

        if (newStatusId === undefined) {
            return;
        }
        this.selectedItem.sale_status_id = newStatusId;

        this.$store.dispatch(this.actionsTypes.UPDATE_ITEM, {
            id: this.selectedItem.id,
            sale_status_id: newStatusId,
        });
        this.selectedItem = null;
    }

    public fetchData() {
        if (this.tableToRefresh) {
            this.$store.state.salesState.statusWithNewSale = null;
        }

        this.isLoading = true;
        this.stopInfiniteScroll = true;

        let url = `/api/v1/${this.props.mockURLName || this.props.name}/statuses/${this.$props.statusId}/${
            this.props.mockURLName || this.props.name
        }?`;

        url += objectToQueryString(this.fetchParams);
        httpClient
            .get(url)
            .then((data) => {
                this.stopInfiniteScroll = false;
                this.items = data.data.data;
                this.isLoading = false;
                this.salesCount = data.data.meta.total;
                this.meta = data.data.meta;
                if (data.data.meta.total <= 10) {
                    this.stopInfiniteScroll = true;
                }
            })
            .catch((err) => {
                logger.error(err);
                this.isLoading = false;
            });
    }

    @Watch('filters')
    public onFiltersChanged(val: any) {
        this.items = [];
        this.fetchData();
    }

    @Watch('search')
    public onSearchChanged(val: any) {
        this.items = [];
        this.fetchData();
    }

    @Watch('tableToRefresh')
    public onTableToRefreshChange(val: any) {
        if (!val || val !== this.$props.statusId) {
            return;
        }
        this.fetchData();
    }
}
