import {ListFilter, ListFilterDataItem} from '@/shared/types';
import {leadSources} from '@/shared/config/sales';

const leadFilters = leadSources.map((e) => {
    return {...e, value: false} as ListFilterDataItem;
});

export const salesFilters: ListFilter[] = [
    {name: 'created_at', type: 'date', label: 'Data utworzenia', description: 'Wybierz zakres dat (od - do)'},
    {name: 'due_date', type: 'date', label: 'Termin', description: 'Wybierz zakres dat (od - do)'},
    {name: 'source', type: 'search', label: 'Źródło', data: leadFilters},
    { name: 'assigned_user_id', type: 'search', label: 'Przydzielona osoba', description: 'Wyszukaj przydzieloną osobę',
    data: [] },

];

