

import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import { parseDateTime } from '@/shared/helpers';

@Component
export default class ContactCard extends Vue {
    @Prop(String) private question!: string;
    @Prop(String) private phone!: string;
    @Prop(String) private email!: string;
    @Prop(String) private name!: string;
    // tslint:disable-next-line:variable-name
    @Prop(String) private created_at!: string;

    private parseDateTime = parseDateTime;
}
