
import { Component, Inject, Prop, Ref, Vue } from 'vue-property-decorator';
import MessageCard from '@/modules/sales/components/messages/message-card.vue';

import { SaleNote, salesModule } from '@/modules/sales/shared';
import { httpClient } from '@/shared/services';
import { FormRules } from '@/shared/validation/form-rules';
import { AxiosResponse } from 'axios';
import { ModuleFetchPayload } from '@/shared/state/template/module-payloads';
import { MetaItem } from '@/shared/types';
import { contrast, hexToRgb } from '@/shared/helpers/colors';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';
import InfiniteScroll from '@/shared/components/elements/infinite-scroll.vue';
import CommentLoader from '@/shared/components/loaders/comment-loader.vue';

@Component({
    components: {
        MessageCard,
        TooltipBtn,
        InfiniteScroll,
        CommentLoader,
    },
})
export default class SaleNotes extends Vue {
    @Inject() public readonly permissionCheck!: (...permissions: string[]) => boolean;

    @Ref('messagesWrapper') public messagesWrapper!: HTMLElement;

    @Prop(String) public color!: string;
    public formRules = new FormRules();
    public notesLoading = false;
    public notes: SaleNote[] = [];
    public meta?: MetaItem = {} as MetaItem;
    public commentMessage = '';
    public firstLoad = true;
    public isEdited: null | number = null;
    public currentPage: number = 1;
    public scrollOrigin = 0;
    public editedComment = '';
    public props = salesModule.moduleProps;
    public whiteColor = hexToRgb('#ffffff');
    public blackColor = hexToRgb('#000000');

    get isDark() {
        return contrast(hexToRgb(this.color), this.whiteColor) > 2;
    }

    get isLight() {
        return contrast(hexToRgb(this.color), this.blackColor) > 2;
    }

    get url() {
        return `api/v1/${this.props.mockURLName || this.props.name}/${this.$route.params.id}/notes`;
    }

    public created() {
        this.fetchComments();
    }

    public isPermitted(action: string) {
        return this.permissionCheck(`sales.notes.${action}`);
    }

    public fetchComments(scrollTo: boolean = true) {
        this.notesLoading = true;
        this.notes = [];
        httpClient
            .get(
                `api/v1/${this.props.mockURLName || this.props.name}/${this.$route.params.id}/notes?page=${
                    this.currentPage
                }`,
            )
            .then(({ data }: AxiosResponse<ModuleFetchPayload<SaleNote>>) => {
                if (data.data) {
                    this.notes = data.data;
                    this.meta = data.meta;
                    this.$nextTick(() => {
                        const messagesWrapper = this.messagesWrapper;
                        messagesWrapper.scrollTo(0, 0);
                    });
                }
            })
            .catch(() => {
                this.$store.commit('SHOW_SNACKBAR', { type: 'error', text: 'Coś poszło nie tak' });
            })
            .finally(() => {
                this.notesLoading = false;
            });
    }

    public editComment(id: number, content: string) {
        this.isEdited = id;
        this.editedComment = content;
    }

    public appendExtra(data: any) {
        this.notes.push(...data.data.data);
    }

    public acceptEditComment(id: number) {
        if (!this.editedComment && this.editedComment === '') {
            return;
        }
        this.notesLoading = true;
        this.isEdited = null;
        httpClient
            .put(`api/v1/${this.props.mockURLName || this.props.name}/notes/${id}`, {
                content: this.editedComment,
            })
            .then(() => {
                const comment = this.notes.find((item: any) => item.id === id);
                if (comment) {
                    comment.content = this.editedComment;
                    comment.updated_at = new Date().toISOString();
                }
            })
            .catch(() => {
                this.$store.commit('SHOW_SNACKBAR', { type: 'error', text: 'Coś poszło nie tak' });
            })
            .finally(() => {
                this.notesLoading = false;
            });
    }
    public sendComment() {
        if (!this.commentMessage && this.commentMessage === '') {
            return;
        }

        this.notesLoading = true;
        httpClient
            .post(`api/v1/sales/${this.$route.params.id}/notes`, {
                content: this.commentMessage,
                user: this.$store.state.authState.user,
            })
            .then(({ data }) => {
                this.$nextTick(() => {
                    const messagesWrapper = this.messagesWrapper;
                    if (messagesWrapper instanceof Element) {
                        messagesWrapper.scrollTo(0, 0);
                    }
                    this.notes.unshift(data.data);
                });
            })
            .catch(() => {
                this.$store.commit('SHOW_SNACKBAR', { type: 'error', text: 'Coś poszło nie tak' });
            })
            .finally(() => {
                this.notesLoading = false;
            });
        this.commentMessage = '';
    }

    public deleteComment(id: number) {
        this.notesLoading = true;
        httpClient
            .delete(`api/v1/sales/notes/${id}`)
            .then(() => {
                this.notes = this.notes.filter((note: any) => note.id !== id);
            })
            .catch(() => {
                this.$store.commit('SHOW_SNACKBAR', { type: 'error', text: 'Coś poszło nie tak' });
            })
            .finally(() => {
                this.notesLoading = false;
            });
    }
}
