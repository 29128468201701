
import Component from 'vue-class-component';
import ListToolbar from '@/shared/components/layout/list/list-toolbar.vue';
import ListComponent from '@/shared/components/layout/list/list-component';
import {ModuleProps} from '@/shared/state/template/module-props';
import {
    Sale,
    SaleDataRequest,
    statusesModule,
    salesModule,
    SaleStatus,
} from '../shared';
import Kanban from '../components/kanban/kanban.vue';
import FilterBar from '@/shared/components/layout/list/filter-bar.vue';
import {salesFilters} from '@/modules/sales/shared/config/filters';
import {ListFilter} from '@/shared/types';
import {logger} from '@/shared/services';
import {mdiFormatListBulleted, mdiViewGrid} from '@mdi/js';
import SalesForm from './form.vue';
import SalesList from './list/list.vue';
import {actionsTypes as adminActionTypes} from '@/modules/admin/shared/state';
import { investmentsModule } from '@/modules/investments/shared/state/module';
import { Provide, Watch } from 'vue-property-decorator';
import Vue from 'vue';
import { callSuper } from '@/shared/helpers';

@Component({
    components: {
        ListToolbar,
        Kanban,
        FilterBar,
        SalesForm,
        SalesList,
    },
})
export default class SalesView extends ListComponent<Sale, SaleDataRequest> {
    @Provide() public salesEventBus = new Vue();
    public headers: object[] = [];
    public actionsTypes = salesModule.actionsTypes;
    public mutationTypes = salesModule.mutationsTypes;
    public fetchAction: string = this.actionsTypes.FETCH_DATA;
    public removeAction: string = this.actionsTypes.REMOVE_ITEM;
    public store: any = this.$store.state.salesState;
    public props: ModuleProps = salesModule.moduleProps;
    public investmentsActions = investmentsModule.actionsTypes;
    public investmentGrouping: boolean = false;

    public kanbanView: boolean = localStorage.getItem('kanban_leads') === 'true' ? true : false;
    public statuses = [];

    public icons = {mdiFormatListBulleted, mdiViewGrid};

    public filters: ListFilter[] = salesFilters;
    public filtersCopy: object = {};
    public searchSales = '';

    public itemSaved() {
        this.formDialog = false;
    }

    public mounted() {
        this.fetchStatuses();
        this.setUsersFilters();
    }

    public created() {
        this.$store.dispatch(this.investmentsActions.FETCH_DATA, {simple: true}).then((response) => {
            response.data.map((item: any) => {
                this.$set(item, 'open', item.number_of_sales > 0);
            });
        });
    }

    public changeView(val: boolean) {
        localStorage.setItem('kanban_leads', String(val));
        this.kanbanView = val;
    }

    get priorities() {
        return this.store.prioritiesState.dataList;
    }

    get defaultStatus() {
        const status = this.statuses.find((e: SaleStatus) => e.default);
        return status ? status as SaleStatus : null;
    }

    get currentUser() {
        return this.$store.state.authState.user;
    }

    public itemTemplate() {
        return {
            sale_status_id: this.defaultStatus ? this.defaultStatus.id : null,
            status: this.defaultStatus,
            assigned_user: this.currentUser || null,
            assigned_user_id: this.currentUser.id || 0,
            source: 'manual',
        };
    }

    public fetchStatuses() {
        this.$store
            .dispatch(statusesModule.actionsTypes.FETCH_DATA, {simple: true})
            .then((data) => {
                this.store.statusesState.data = data.data;
                this.statuses = data.data;
            })
            .catch((error) => {
                logger.error(error);
            });
    }

    public async setUsersFilters() {
        const index = 3;
        let allUsers = await this.$store.dispatch(adminActionTypes.FETCH_USER_DATA, {
            simple: true,
            filters: {search: ''},
            page: 1,
        });
        allUsers = allUsers.data;

        this.filters[index].data = allUsers;
    }

    public fetchFiltersData(index: number, actionsTypes: { [k: string]: string }) {
        this.$store
            .dispatch(actionsTypes.FETCH_DATA, {simple: true})
            .then((res) => {
                this.filters[index].data = res.data.map((el: any) => {
                    return {name: el.id, label: el.name, value: false};
                });
            })
            .catch((error) => {
                logger.error(error);
            });
    }

    public onFiltersChange(filters: any) {
        this.filtersCopy = filters;
        this.assignFilters(filters);
    }

    public onItemSaved({data}: any) {
        this.formDialog = false;
        this.store.statusWithNewSale = data.sale_status_id;
    }

    public onItemNext(data: any) {
        this.editedItem = this.itemTemplate() as unknown as SaleDataRequest;
        this.formDialog = true;
        this.store.statusWithNewSale = data.sale_status_id;
    }

    @Watch ('investmentGrouping')
    public fetchDataInvestment() {
        this.fetchData();
    }
}
