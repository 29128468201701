
import {Component, Vue} from 'vue-property-decorator';
import DetailsComponent from '@/shared/components/layout/details/details-component';
import {parseDate} from '@/shared/helpers';
import TopToolbar from '@/shared/components/layout/details/top-toolbar.vue';

import {mdiPencil} from '@mdi/js';
import SalesForm from '@/modules/sales/components/form.vue';

import StatusesPicker from '@/modules/sales/components/pickers/status-picker.vue';

import {
    salesModule,
    Sale,
    SaleTasksList,
    SaleTasksListDataRequest,
    tasksListsModule,
    SaleTasksListTaskDataRequest, SaleTasksListTask
} from '@/modules/sales/shared';
import {FormRules} from '@/shared/validation/form-rules';
import SaleNotes from '@/modules/sales/components/messages/notes.vue';
import UserPicker from '@/modules/sales/components/pickers/user-picker.vue';
import {investmentSubjectsModule} from '@/modules/investments/shared/state/submodules';
import {ModuleShowPayload} from '@/shared/state/template/module-payloads';
import ContactCard from './details/contact.vue';
import ChecklistCard from '@/modules/sales/components/details/checklist.vue';
import {leadSources} from '@/shared/config/sales';
import {contrast, hexToRgb} from '@/shared/helpers/colors';
import {ModuleState} from '@/shared/state/template/module-state';
import {findLastId} from '@/modules/investments/components/generator/helpers';
import {tasksListTasksModule} from '@/modules/sales/shared/state/settings/tasks-list';
import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';

@Component({
    props: {
        id: String,
    },
    components: {
        TopToolbar,
        ActionConfirmDialog
    },
})
export default class SalesDetails extends DetailsComponent<SaleTasksList> {
    public store: ModuleState<SaleTasksList, SaleTasksListDataRequest> = this.$store.state.salesState.tasksListsState;
    public tasksListTasksState = this.$store.state.salesState.tasksListTasksState;
    public actionsTypes = tasksListsModule.actionsTypes;
    public tasksActions = tasksListTasksModule.actionsTypes;
    public showAction: string = this.actionsTypes.SHOW_ITEM;
    public valid: boolean = false;
    public activeInput: number = 0;
    public formRules: FormRules = new FormRules();
    public form!: HTMLFormElement;
    public taskIndex: number = 0;

    get currentTask() {
        if (!this.item || !this.item.tasks) {
            return null;
        }
        return this.item.tasks[this.activeInput];
    }

    get title() {
        return this.item ? this.item.name : '';
    }

    public created() {
        this.fetchDetails();
    }

    public mounted() {
        this.form = this.$refs.form as HTMLFormElement;
    }

    public isPermitted(action: string) {
        return this.permissionCheck(`sales.tasks-lists.${action}`);
    }

    public async saveTask(task: SaleTasksListTaskDataRequest, index: number) {
        await (this.$refs.form as HTMLFormElement).validate();
        if (this.valid) {
            const action = task.id > 0 ? this.tasksActions.UPDATE_ITEM : this.tasksActions.STORE_ITEM;
            this.$store.dispatch(action, {...task, sale_tasks_list_id: Number(this.id)}).then((response) => {
                this.$set(this.item.tasks, index, response.data);
            });
        } else {
            this.activeInput = index;
        }
    }

    public openRemoveTaskDialog(index: number) {
        this.activeInput = index;
        this.taskIndex = index;
        this.tasksListTasksState.removeDialog = true;
    }

    public removeTask(task: SaleTasksListTask, index: number) {
        if (task.id > 0) {
            this.$store.dispatch(this.tasksActions.REMOVE_ITEM, task).then((response) => {
                this.activeInput = -1;
                this.$delete(this.item.tasks, index);
                this.tasksListTasksState.removeDialog = false;
            });
        }
    }

    public cancelTaskCreation(index: number) {
        this.activeInput = -1;
        this.$delete(this.item.tasks, index);
    }

    public addTask() {
        this.item.tasks.push({id: ((this.item.tasks.length + 1) * -1)} as SaleTasksListTaskDataRequest);
        this.activeInput = this.item.tasks.length - 1;
        this.$nextTick(() => {
            if (this.$refs.taskInput) {
                const taskInputs: HTMLInputElement[] = this.$refs.taskInput as HTMLInputElement[];
                taskInputs[this.activeInput].focus();
            }
        });
    }
}
